<template>
  <ValidationObserver class="create-new-wishlist-form__container">
    <div class="create-new-wishlist-form">
      <CustomInput
        v-model="wishlistLabel"
        class="create-new-wishlist-form__label"
        input-name="wishlist-label"
        input-id="wishlist-label"
        input-label="Nome della lista preferiti"
        validate-rule="required"
        :override-style="{ width: '100%' }"
      />
      <CustomButton
        class="create-new-wishlist-form__button"
        :disabled="!wishlistLabel"
        :is-full-width="true"
        type="submit"
        theme="yellow"
        @click="handleCreateNewWishlist"
        specific-width="fit-content"
      >
        {{
          createAndAddToWishlist
            ? 'CREA NUOVA LISTA PREFERITI E AGGIUNGI PRODOTTO'
            : 'CREA NUOVA LISTA PREFERITI'
        }}
      </CustomButton>
    </div>
  </ValidationObserver>
</template>

<script>
import { defineComponent, shallowRef } from '@nuxtjs/composition-api';
import { CustomInput, CustomButton } from '~/components/General/FormElements';
import { ValidationObserver } from 'vee-validate';
import { useUserWishlists, useUiState, useTranslation } from '~/composables';

export default defineComponent({
  name: 'CreateNewWishlistForm',
  components: { ValidationObserver, CustomButton, CustomInput },
  props: {
    createAndAddToWishlist: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { $gt } = useTranslation('translations');
    const { createUserWishlist, addItemToWishlist } = useUserWishlists();
    const {
      wishlistModalData,
      toggleCreateWishlistModal,
      toggleWishlistSidebar,
      toggleWishlistModal,
    } = useUiState();

    const wishlistLabel = shallowRef();

    const handleCreateNewWishlist = async () => {
      await createUserWishlist({
        label: wishlistLabel.value,
        privacy: 'PRIVATE',
        sharing: true,
      });
      if (props.createAndAddToWishlist) {
        await addItemToWishlist({ product: wishlistModalData.value });
      }

      if (!props.createAndAddToWishlist) toggleCreateWishlistModal();
      if (props.createAndAddToWishlist) toggleWishlistModal();
      toggleWishlistSidebar();
    };

    return {
      wishlistLabel,
      handleCreateNewWishlist,
      $gt,
    };
  },
});
</script>

<style lang="scss" scoped>
.create-new-wishlist-form {
  &__container {
    @include flex-column;
    align-items: center;
    justify-content: center;
  }
  @include flex-column;
  gap: 1rem;
}
@include from-desktop-min {
  .create-new-wishlist-form {
    width: 100%;
    &__container {
      width: 70%;
      margin: 0 auto;
    }
    &__button {
      margin: 0 auto;
    }
  }
}
</style>
